import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel';
import { ActionCreators } from '../../actions/index'
import CSSTransition from 'react-transition-group/CSSTransition';
import bag1 from '../../images/foodBag.jpg'
import bag2 from '../../images/foodBag2.jpg'
import bag3 from '../../images/foodBag3.jpg'
import { theme } from '../../config';
import Button from '../UI/Button';
import QuizNavigation from './QuizNavigation'
import Footer from '../Footer';

class Completed extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {}
  }
  onClickOut = async (url) => {
    await window.gtag('event', 'Step Completed', {
      'event_category': 'Quiz',
      'event_label': 'Went to Checkout'
    });
    await ReactPixel.trackCustom( 'PPAddToCart' )
    window.location.href = url
  }
  toCheckout = () => {

  }
  componentDidMount(){
    
  }
  render() {
    return (
      <CSSTransition
        in={this.props.open}
        timeout={1000}
        classNames="quiz-slide"
        unmountOnExit={true}
        mountOnEnter={true}
      >
        <div className={"question-container eight"} style={{borderColor: theme.primary.regular}}> 
          <div className={"question-container-inner"}>
            <div className="completed-wrap">
              <div className="bag desktop">
                <div>
                  {
                    this.props.redux.answer_1.number_of_cats === 1 && 
                    <img src={bag1} alt="PrettyLitter.com" className="completed-bag"/>
                  }
                  {
                    this.props.redux.answer_1.number_of_cats === 2 && 
                    <img src={bag2} alt="PrettyLitter.com" className="completed-bag"/>
                  }
                  {
                    this.props.redux.answer_1.number_of_cats === 3 && 
                    <img src={bag3} alt="PrettyLitter.com" className="completed-bag"/>
                  }
                </div>
              </div>
              {
                this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats === 1 &&
                  <div className="copy">
                    <div>
                      <p className="title">It’s a Purrrfect Match for {this.props.redux.answer_2.cat_1_name}{this.props.redux.answer_2.cat_2 && ' and '+this.props.redux.answer_2.cat_2_name}{this.props.redux.answer_2.cat_3 && ' AND '+this.props.redux.answer_2.cat_3_name}!</p>
                    </div>
                    <p className="desc">One delicious &amp; nutritious bag per month.</p>
                    <p className="desc"><span style={{textDecoration: 'line-through'}}>$30</span> &nbsp;$25 with Free Shipping!</p>
                    <p className="desc" style={{color: theme.primary.regular}}>Your monthly supply is delivered hassle-free 
(try now and cancel anytime).</p>
                    <a onClick={()=>this.onClickOut('https://prettylittercats.com/pages/checkout?vid=13509538873405')} href="#">
                      <Button text="Checkout"/>
                    </a>
                  </div>
              }
              {
                this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats === 2 &&
                  <div className="copy">
                    <div>
                    <p className="title">It’s a Purrrfect Match for <span style={{textTransform: 'capitalize'}}>
                        {this.props.redux.answer_2.cat_1_name}</span>
                        {this.props.redux.answer_2.cat_2 && ' and '}
                        <span style={{textTransform: 'capitalize'}}>{this.props.redux.answer_2.cat_2 && this.props.redux.answer_2.cat_2_name}</span>
                      !</p>
                    </div>
                    <p className="desc">Two delicious &amp; nutritious bags per month<span> (1 per cat).</span></p>
                    <p className="desc"><span style={{textDecoration: 'line-through'}}>$60</span> &nbsp;$49 with Free Shipping!</p>
                    <p className="desc" style={{color: theme.primary.regular}}>Your monthly supply is delivered hassle-free 
(try now and cancel anytime).</p>
                    <a onClick={()=>this.onClickOut('https://prettylittercats.com/pages/checkout?vid=13509550538813')} href="#">
                      <Button text="Checkout"/>
                    </a>
                  </div>
              }
              {
                this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats === 3 &&
                  <div className="copy">
                    <div>
                    <p className="title">It’s a Purrrfect Match for <span style={{textTransform: 'capitalize'}}>
                        {this.props.redux.answer_2.cat_1_name}</span>
                        {this.props.redux.answer_2.cat_2 && ' and '}
                        <span style={{textTransform: 'capitalize'}}>{this.props.redux.answer_2.cat_2 && this.props.redux.answer_2.cat_2_name}
                        {this.props.redux.answer_2.cat_3 && ' AND '+this.props.redux.answer_2.cat_3_name}</span>
                      !</p>
                    </div>
                    <p className="desc">Three delicious &amp; nutritious bags per month<span> (1 per cat).</span></p>
                    <p className="desc"><span style={{textDecoration: 'line-through'}}>$90</span> &nbsp;$65 with Free Shipping!</p>
                    <p className="desc" style={{color: theme.primary.regular}}>Your monthly supply is delivered hassle-free 
(try now and cancel anytime).</p>
                    <a onClick={()=>this.onClickOut('https://prettylittercats.com/pages/checkout?vid=13509551489085')} href="#">
                      <Button text="Checkout"/>
                    </a>
                  </div>
              }
            </div>
          </div>
          <QuizNavigation page={9}/>
          <Footer light style={{position: 'absolute', bottom: -100, fontSize: 12}}/>
        </div>
      </CSSTransition>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Completed)