import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../actions/index'
import CSSTransition from 'react-transition-group/CSSTransition';
import { theme } from '../../config';
import Button from '../UI/Button';

import icon from '../../images/q7-icon.png'
import QuizNavigation from './QuizNavigation';
import Footer from '../Footer';

class Seven extends Component<{}> {
  
  constructor(props){
    super(props)
  
    this.state = {}
  }
  
  selectTummy = (props) => {
    if(this.state.loading) return true
    this.setState({
      [`cat_${props.catNumber}_tummy_issues`]: props.text
    })
  }
  submitOnSelectTummy = async (selection) => {
    if(this.state.loading) return true
    let text = selection.text
    this.setState({
      [`cat_1_tummy_issues`]: text,
      loading: text
    })
    let info = await fetch('/api/q7',{
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({info: {[`cat_1_tummy_issues`]: text, completed: true}, user: this.props.redux.user_info})})
        .then(r=> r.json())
    
    info.answer_7.completed = true
    
    let totalCats = this.props.redux.answer_1.number_of_cats
    window.gtag('event', 'Question 7', {
      'event_category': 'Quiz',
      'event_label': totalCats > 1 ? totalCats+' cats' : totalCats+' cat'
    })
    setTimeout(()=>this.setState({loading: false}), 1000)
    this.props.answerSeven(info.answer_7)
  }
  submitCatDigestion = async () => {
    let validated = true

    for (let i = 0; i < this.props.redux.answer_1.number_of_cats; i++) {
      let gender = this.state[`cat_${i+1}_tummy_issues`]
      if(!gender){
        validated = false
      }
    }

    if(validated){
        
      let info = await fetch('/api/q7',{
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({info: {...this.state, completed: true}, user: this.props.redux.user_info})})
          .then(r=> r.json())
      
      info.answer_7.completed = true
      
      let totalCats = this.props.redux.answer_1.number_of_cats
      window.gtag('event', 'Question 7', {
        'event_category': 'Quiz',
        'event_label': totalCats > 1 ? totalCats+' cats' : totalCats+' cat'
      })
      setTimeout(()=>this.setState({loading: false}), 1000)
      this.props.answerSeven(info.answer_7)
    } else {
      clearTimeout(this.validationReset)

      this.setState({invalidEntry: false}, ()=>{
        this.setState({invalidEntry: true})
        this.validationReset = setTimeout(()=>{
          this.setState({invalidEntry: false})
        }, 2000)
      })
    }
  }
  async componentWillReceiveProps(nuProps){
    if(nuProps.redux.answer_1.number_of_cats){
      let cats = this.props.redux.answer_1.number_of_cats
      for (let i = 0; i < cats; i++) {
        if(!this.state[`cat_${i+1}_env`]){
          await this.setState({[`cat_${i+1}_tummy_issues`]: false})
        }
      }
    }
  }
  render() {
    return (
      <CSSTransition
        in={this.props.open}
        timeout={1000}
        classNames="quiz-slide"
        unmountOnExit
      >
        
        <div className={"question-container seven"} style={{borderColor: theme.primary.regular}}> 
        {
          this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats === 1 &&
            <CatDigestion loading={this.state.loading} onSelect={this.submitOnSelectTummy} selection={this.state.cat_1_tummy_issues} catNumber={1} catNames={this.props.redux.answer_2}/>
        }
        {
          this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats > 1 &&
            <CatDigestion onSelect={this.selectTummy} selection={this.state.cat_1_tummy_issues} catNumber={1} catNames={this.props.redux.answer_2}/>
        }
        {
          this.props.redux.answer_2 && this.props.redux.answer_1.number_of_cats > 1 &&
            <CatDigestion onSelect={this.selectTummy} selection={this.state.cat_2_tummy_issues} catNumber={2} catNames={this.props.redux.answer_2}/>
        }
        {
          this.props.redux.answer_2 && this.props.redux.answer_1.number_of_cats > 2 &&
            <CatDigestion onSelect={this.selectTummy} selection={this.state.cat_3_tummy_issues} catNumber={3} catNames={this.props.redux.answer_2}/>
        }
        {
          this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats > 1 &&
            <Button
              text="NEXT"
              onClick={this.submitCatDigestion}
              style={{marginBottom: 20, marginTop: 20, maxWidth: 210}}/>
        }
        { this.state.invalidEntry && this.props.open &&
          <p className={"ani-error"+' total'+this.props.redux.answer_1.number_of_cats } style={{color: theme.error.light, marginLeft: 0, fontSize: 12, position: 'absolute'}}>Please make sure you select an option</p>
        }
        <p className="question-footer-text"><img src={icon} alt="" style={{maxWidth: 20, height: 'auto', position: 'relative', bottom: -3}}/> PrettyPlease provides dynamic amino acids for ideal muscle tone</p>
        <QuizNavigation page={7} />
        <Footer light style={{position: 'absolute', bottom: -100, fontSize: 12}}/>
      </div>

      </CSSTransition>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Seven)

const CatDigestion = (props) => (
  <div className={"question-container-inner"}>
    Does { props.catNames[`cat_${props.catNumber}_name`] } have digestive issues?
    <div className="env-selector">
      <Button
        style={{maxWidth: 210}}
        onClick={props.onSelect}
        catNumber={props.catNumber}
        loading={props.loading === "Yes"}
        text="Yes"
        focused={props.selection === 'Yes'}
        focusBackgroundColor={theme.primary.regular}
        focusTextColor={theme.primary.contrast} />
      <Button
        style={{maxWidth: 210}}
        onClick={props.onSelect}
        catNumber={props.catNumber}
        loading={props.loading === "No"}
        text="No"
        focused={props.selection === 'No'}
        focusBackgroundColor={theme.primary.regular}
        focusTextColor={theme.primary.contrast} />
    </div>
  </div>
)