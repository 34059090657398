import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../actions/index'
import CSSTransition from 'react-transition-group/CSSTransition';
import Button from '../UI/Button';
import { theme } from '../../config';

import icon from '../../images/q3-icon.png'
import QuizNavigation from './QuizNavigation';
import Footer from '../Footer';

class Three extends Component<{}> {
    
  constructor(props){
    super(props)

    this.state = {}
    this.loading = false
  }
  selectBreed = (props) => {
    this.setState({
      [`cat_${props.catNumber}_gender`]: props.text
    })
  }
  submitOnSelect = async (selection) => {
    this.setState({loading: selection.text, cat_1_gender: selection.text})
    let data = {
      cat_1_gender: selection.text,
      completed: true
    }
    
    let info = await fetch('/api/q3',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({info: data, user: this.props.redux.user_info})})
        .then(r=> r.json())
    
    this.props.answerThree(info.answer_3)
    let totalCats = this.props.redux.answer_1.number_of_cats

    window.gtag('event', 'Question 3', {
      'event_category': 'Quiz',
      'event_label': totalCats > 1 ? totalCats+' cats' : totalCats+' cat'
    })
  }
  submitGender = async () => {

    let validated = true

    for (let i = 0; i < this.props.redux.answer_1.number_of_cats; i++) {
      let gender = this.state[`cat_${i+1}_gender`]
      if(!gender){
        validated = false
      }
    }

    if(validated){
      let info = await fetch('/api/q3',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({info: {...this.state, completed: true}, user: this.props.redux.user_info})})
          .then(r=> r.json())

      this.props.answerThree(info.answer_3)
      let totalCats = this.props.redux.answer_1.number_of_cats
      window.gtag('event', 'Question 3', {
        'event_category': 'Quiz',
        'event_label': totalCats > 1 ? totalCats+' cats' : totalCats+' cat'
      })
    } else {
      clearTimeout(this.validationReset)

      this.setState({invalidEntry: false}, ()=>{
        this.setState({invalidEntry: true})
        this.validationReset = setTimeout(()=>{
          this.setState({invalidEntry: false})
        }, 2000)
      })
    }
  }
  async componentWillReceiveProps(nuProps){
    if(nuProps.redux.answer_1.number_of_cats){
      let cats = this.props.redux.answer_1.number_of_cats
      for (let i = 0; i < cats; i++) {
        if(!this.state[`cat_${i+1}_gender`]){
          await this.setState({[`cat_${i+1}_gender`]: false})
        }
      }
    }
  }
  render() {
    return (
      <CSSTransition
        in={this.props.open}
        timeout={1000}
        classNames="quiz-slide"
        unmountOnExit
        onEnter={() => {
          console.log({props: this.props})
        }}
        onExited={() => {
          this.setState({
            loading: false,
          });
        }}
      >
      <div className={"question-container three"} style={{borderColor: theme.primary.regular}}> 
        {
          this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats === 1 &&
            <CatGender
              catNumber={1}
              loading={this.state.loading}
              onSelect={this.submitOnSelect}
              numberOfCats={this.props.redux.answer_1.number_of_cats}
              selection={this.state.cat_1_gender}
              catNames={this.props.redux.answer_2}/>
        }
        {
          this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats > 1 &&
            <CatGender
              catNumber={1}
              onSelect={this.selectBreed}
              numberOfCats={this.props.redux.answer_1.number_of_cats}
              selection={this.state.cat_1_gender}
              catNames={this.props.redux.answer_2}/>
        }
        {
          this.props.redux.answer_2 && this.props.redux.answer_1.number_of_cats > 1 &&
            <CatGender
              catNumber={2}
              numberOfCats={this.props.redux.answer_1.number_of_cats}
              onSelect={this.selectBreed}
              selection={this.state.cat_2_gender}
              catNames={this.props.redux.answer_2}/>
            }
        {
          this.props.redux.answer_2 && this.props.redux.answer_1.number_of_cats > 2 &&
            <CatGender
              catNumber={3}
              numberOfCats={this.props.redux.answer_1.number_of_cats}
              onSelect={this.selectBreed}
              selection={this.state.cat_3_gender}
              catNames={this.props.redux.answer_2}/>
        }

        {
          this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats > 1 &&
          <Button text="NEXT" onClick={this.submitGender} style={{marginBottom: 40, marginTop: 20}}/>
        }

        { this.state.invalidEntry && this.props.open &&
          <p className={"ani-error"+' total'+this.props.redux.answer_1.number_of_cats } style={{color: theme.error.light, marginLeft: 0, fontSize: 12, position: 'absolute'}}>Please make sure you select an option</p>
        }
        <p className="question-footer-text"><img src={icon} alt="" style={{maxWidth: 20, height: 'auto', position: 'relative', bottom: -5}}/> Cat lovers know our products from ABC Family,<br/>The Oprah Magazine, and Reader’s Digest!</p>
        <Footer light style={{position: 'absolute', bottom: -100, fontSize: 12}}/>
        <QuizNavigation page={3} />
      </div>
      </CSSTransition>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Three)

const CatGender = (props) => {
  console.log({three_props: props})
  return (
  <div className={"question-container-inner"}>
    My cat { props.catNames[`cat_${props.catNumber}_name`] } is a:
    <div className="breed-selector">
      <Button
        onClick={props.onSelect}
        catNumber={props.catNumber}
        text="Male"
        loading={props.loading === 'Male'}
        focused={props.loading === 'Male' || props.selection === 'Male'}
        focusBackgroundColor={theme.primary.regular}
        focusTextColor={theme.primary.contrast} />
      <Button
        onClick={props.onSelect}
        catNumber={props.catNumber}
        loading={props.loading === 'Female'}
        focused={props.loading === 'Female' || props.selection === 'Female'}
        text="Female"
        focusBackgroundColor={theme.primary.regular}
        focusTextColor={theme.primary.contrast} />
    </div>
  </div>
)}