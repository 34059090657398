import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../../actions/index'
import ProgressBarUnit from './ProgressBarUnit';
import { sizes } from '../../../config/sizes';
import ProgressCircleEnd from './ProgressCircleEnd';

class ProgressBarContainer extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      complete: false,
      lineWidth: window.innerWidth < 768 ? sizes.progress.mobile : sizes.progress.desktop
    }
  }
  componentDidMount(){
    if(window.innerWidth < 768){
      this.setState({lineWidth: sizes.progress.mobile})
    } else {
      this.setState({lineWidth: sizes.progress.desktop})
    }
    window.addEventListener("resize", ()=>{
      if(window.innerWidth < 768){
        this.setState({lineWidth: sizes.progress.mobile})
      } else {
        this.setState({lineWidth: sizes.progress.desktop})
      }
    })
  }
  render() {
    return (
      <div id="progress-container-wrap" style={{maxWidth: 270, margin: window.innerWidth > 767 ? '40px auto' : '20px auto'}}>
        <ProgressBarUnit
          width={this.state.lineWidth}
          completed={this.props.redux.answer_1.completed}
        />
        <ProgressBarUnit
          width={this.state.lineWidth}
          completed={this.props.redux.answer_2.completed}
        />
        <ProgressBarUnit
          width={this.state.lineWidth}
          completed={this.props.redux.answer_3.completed}
        />
        <ProgressBarUnit
          width={this.state.lineWidth}
          completed={this.props.redux.answer_4.completed}
        />
        <ProgressCircleEnd completed={this.props.redux.answer_4.completed} />
      </div>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBarContainer)