import React, { Component } from 'react'
import { NavLink as Link } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel';

// Styles
import { theme } from '../config';
import './css/home.css'

// Images
import contentLeftImg from '../images/lp-left.jpg'
import check from '../images/check.png'
import logoLight from '../images/logo-white.png'

import step1 from '../images/lp-steps-1.jpg'
import step2 from '../images/lp-steps-2.jpg'
import step3 from '../images/lp-steps-3.jpg'
import Footer from '../components/Footer';

const CheckMark = () => (
  <img src={check} alt=""/>
)
class Home extends Component<{}> {
  componentDidMount(){
    ReactPixel.pageView();
    setTimeout(()=>{
      if(navigator.appVersion.indexOf('MSIE 9.0') > -1){
        window.flexibility(document.documentElement);
      }
    }, 500)
  }
  render() {
    return (
        <div id="homePage">
          <div className="header">
            <div>
              <img src={logoLight} alt="PrettyPlease Cat Food"/>
            </div>
          </div>
          <div className="sub-header" style={{background: theme.primary.regular, color: theme.primary.contrast}}>
            <p>The ultimate feline formula delivered monthly</p>
          </div>
          <div className="main-content" id="the-content">
            <div className="content-left">
              <img src={contentLeftImg} alt="Pretty Kitty" />
            </div>
            <div className="content-right">
              <h1>Try PrettyPlease <span style={{color: theme.primary.regular}}>Today!</span></h1>
              <li><CheckMark />Engineered for life-long whole body health</li>
              <li><CheckMark />Designed by certified feline nutritionists</li>
              <li><CheckMark />Only the best ingredients, cats love it!</li>
              <p style={{fontSize: 21, fontWeight: 400, color: theme.primary.regular}}>Help us get to know you and your feline</p>
              <Link to="/quiz">
                <button style={{background: theme.primary.regular, color: theme.primary.contrast}}>Get Started</button>
              </Link>
            </div>
          </div>
          <div className="how-it-works">
            <div className="">
              <h2>How it all works</h2>
              <div className="steps-wrap">
                <div className="step one">
                  <img src={step1} alt="Step 1"/>
                  <h3>Tell us about your cats</h3>
                  <p>You’ll get the precise amount you need to last an entire month!</p>
                </div>
                <div className="step two">
                  <img src={step2} alt="Step 2"/>
                  <h3>Get it delivered free</h3>
                  <p>PrettyPlease is conveniently delivered to your door each month. We'll pay the shipping!</p>
                </div>
                <div className="step three">
                  <img src={step3} alt="Step 3"/>
                  <h3>Be your cat's hero</h3>
                  <p>Time to feast on a perfectly balanced, ultra nutritious and instinctively flavorful bowl of PrettyPlease.</p>
                </div>  
              </div>
            </div>
          </div>
          <div style={{padding: 15, textAlign: 'center'}}>
            <Link to="/quiz" style={{marginTop: 20, maxWidth: 375, width: '100%'}}>
              <button style={{background: theme.primary.regular, color: theme.primary.contrast, paddingLeft: 20, paddingRight: 20}}>Tell Us About Your Cats</button> 
            </Link>
          </div>
          <Footer />
        </div>
    )
  }
}

export default Home