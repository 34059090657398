import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../actions/index'
import { theme } from '../../config';

import GoBack from  '../../images/undo.png'
class QuizNavigation extends Component<{}> {
  goBack = () => {
    let currentPage = this.props.page
    let previousPage = currentPage - 1
    let { redux } = this.props
    
    let backObject = Object.assign({}, redux[`answer_${previousPage}`], {completed: false})
    console.log({previouseState: redux[`answer_${previousPage}`]})
    console.log({backObject})

    switch (previousPage) {
      case 1:
      this.props.answerOne(backObject)
        break;
      case 2:
      this.props.answerTwo(backObject)
        break;
      case 3:
      this.props.answerThree(backObject)
        break;
      case 4:
      this.props.answerFour(backObject)
        break;
      case 5:
      this.props.answerFive(backObject)
        break;
      case 6:
      this.props.answerSix(backObject)
        break;
      case 7:
      this.props.answerSeven(backObject)
        break;
      case 8:
      this.props.answerEight(backObject)
        break;
    
      default:
        break;
    }
    
    
  }
  render() {
    return (
      <div id="GoBack" onClick={this.goBack}>
        <div>
          <img style={{width: 25, height: 25}}src={GoBack} alt="go back" />
        </div>
        <span style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: 300,
          color: theme.primary.regular
        }}>
          Go Back
        </span>
      </div>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizNavigation)