import * as types from './types'

export function answerOne( data ) {
	return {
		type: types.Q1,
		payload: data
	}
}

export function answerTwo( data ) {
	return {
		type: types.Q2,
		payload: data
	}
}

export function answerThree( data ) {
	return {
		type: types.Q3,
		payload: data
	}
}

export function answerFour( data ) {
	return {
		type: types.Q4,
		payload: data
	}
}

// export function answerFive( data ) {
// 	return {
// 		type: types.Q5,
// 		payload: data
// 	}
// }

// export function answerSix( data ) {
// 	return {
// 		type: types.Q6,
// 		payload: data
// 	}
// }

// export function answerSeven( data ) {
// 	return {
// 		type: types.Q7,
// 		payload: data
// 	}
// }

// export function answerEight( data ) {
// 	return {
// 		type: types.Q8,
// 		payload: data
// 	}
// }

// export function answerNine( data ) {
// 	return {
// 		type: types.Q9,
// 		payload: data
// 	}
// }