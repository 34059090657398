import React, { Component } from 'react'

class ProgressBarUnitBGLayer extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {}
  
    this.circle = React.createRef();
    this.svg = React.createRef();
  }
  componentDidMount(){
    const circle = this.circle.current;
    const radius = circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;
    
    // Offset is used to determin what a full circle is, and then use the to reduce the dasharray offset
    const offset = circumference - (this.props.percent || 100) / 100 * circumference;
    
    // Setting the dash array/offset gives us a gap to work with when animating
    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = circumference;
    
    this.props.setCircumference({
      circumference,
      offset
    })
    
    this.setState({
      circumference,
      offset
    })
  }
  render() {
    return (
      <div className="progress-bar-bg-wrap">
        <svg
          className="progress-ring background"
          height={"10"}
          width={"10"}
          id={this.props.id}
          style={{display: 'block'}}
        >
          <circle
            ref={this.circle}
            className={"progress-ring__circle no-transition"}
            strokeWidth={"3"}
            stroke={this.props.color}
            style={{strokeDashoffset: this.state.offset}}
            fill={this.props.color}
            r={this.props.r || '3'}
            cx={this.props.cx || "5"}
            cy={this.props.cy || "5"}
          />
        </svg>
        <svg
          height="10"
          width={this.props.width+1}
          style={{marginRight: -2, marginLeft: -2}}
        >
            <path
              className={"progress-ring__line"}
              stroke={this.props.color}
              strokeWidth={this.props.strokeWidth || 2}
              d={this.props.d || `M0 5 l${this.props.width} 0`}
            />
        </svg>
      </div>
    )
  }
}

export default ProgressBarUnitBGLayer