import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../actions/index'
import CSSTransition from 'react-transition-group/CSSTransition';
import { theme } from '../../config';
import Button from '../UI/Button';

import icon from '../../images/q1-icon.png'
import Footer from '../Footer';

class One extends Component<{}> {
  constructor(props){
    super(props)

    if(this.props && this.props.redux.answer_1 && this.props.redux.answer_1.completed){
      this.state = this.props.redux.answer_1
    } else {
      this.state = {
        number_of_cats: false,
        loading: false,
      }
    }
  }
  selectCatAmount = async (amount) => {
    await this.setState({number_of_cats: amount, loading: true})
    let info = await fetch('/api/q1',{
                        method: 'POST',
                        headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({number_of_cats: amount, completed: true})})
                       .then(r=> r.json())
                       
    this.props.answerOne(info.answer_1)
    this.props.answerTwo({completed:false})
    let user = {
      ip: info.ip,
      email: info.email,
      returning: info.returning ? true : false,
      first_visit: info.first_visit,
      client_id: info._id,
    }
      window.gtag('event', 'Question 1', {
      'event_category': 'Step Completed',
      'event_label': amount > 1 ? amount+' cats' : amount+' cat'
    });
    this.props.updateUser(user)
    setTimeout(
      ()=>{
        this.setState({number_of_cats: null, loading: false})
    }, 1000)
  }
  render() {
    return (
      <CSSTransition
        in={this.props.open}
        timeout={1000}
        classNames="quiz-slide"
        unmountOnExit
      >
        <div className="question-container one clearfix" style={{borderColor: theme.primary.regular}}>
          <div className="question-container-inner">
            <p>How many cats do you have?</p>
            <div className="how-many-cats">
              <Button onClick={()=>this.selectCatAmount(1)} loading={this.state.number_of_cats === 1  && this.state.loading ? true : false} text="1" style={{marginBottom: 20}}/>
              <Button onClick={()=>this.selectCatAmount(2)} loading={this.state.number_of_cats === 2  && this.state.loading ? true : false} text="2" style={{marginBottom: 20}}/>
              <Button onClick={()=>this.selectCatAmount(3)} loading={this.state.number_of_cats === 3  && this.state.loading ? true : false} text="3+" style={{marginBottom: 20}}/>
            </div>
            <p className="question-footer-text"><img src={icon} alt="" style={{maxWidth: 20, height: 'auto', position: 'relative', bottom: -7, right: 10}}/> A group of kittens is called a "kindle"</p>
          </div>
          <Footer light style={{position: 'absolute', bottom: -100, fontSize: 12}}/>
        </div>
      </CSSTransition>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(One)