// export const SET_SEARCHED_RECIPES = 'SET_SEARCHED_RECIPES'
// export const ADD_RECIPE = 'ADD_RECIPE'
export const Q1 = 'Q1'
export const Q2 = 'Q2'
export const Q3 = 'Q3'
export const Q4 = 'Q4'
export const Q5 = 'Q5'
export const Q6 = 'Q6'
export const Q7 = 'Q7'
export const Q8 = 'Q8'
export const Q9 = 'Q9'
export const USER_INFO = 'USER_INFO'
