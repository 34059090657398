import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../../actions/index'
import WheelIconAnimation from '../../UI/WheelIconAnimation'
import { theme } from '../../../config'


class ProgressCircleEnd extends Component<{}> {
  constructor(props){
    super(props)

    this.state = {
      
    }
  
    this.circle = React.createRef();
    this.svg = React.createRef();
  }
  componentDidMount(){
    const circle = this.circle.current;
    const radius = circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;
    
    // Offset is used to determin what a full circle is, and then use the to reduce the dasharray offset
    const offset = circumference - (this.props.percent || 100) / 100 * circumference;
    
    // Setting the dash array/offset gives us a gap to work with when animating
    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = circumference;
    
    this.setState({
      circumference,
      offset
    })
  }
  render() {
    return (
      <span style={{height: 10, width: 10, position: 'relative'}}>
        {
          this.props.completed &&
            <WheelIconAnimation
              style={{
                position: 'absolute',
                width: 20,
                height: 20,
                left: -5,
                top: -5,
                zIndex: 10
              }}
              delay="2000"
            />
        }
        <svg
          ref={this.svg}
          style={{position: 'absolute'}}
          className="progress-ring"
          height={this.props.height || "10"}
          width={this.props.width || "10"}
          id={this.props.id}
          preserveAspectRatio="none"
          >
          <circle
            ref={this.circle}
            className={"progress-ring__t-0 progress-ring__circle"+this.props.completed&&'delay1'}
            strokeWidth={"3"}
            style={{strokeDashoffset: this.state.offset}}
            stroke={this.props.completed ? theme.progress.regular : '#ccc'}
            fill={this.props.completed ? theme.progress.regular : '#ccc'}
            r={this.props.r || '3'}
            cx={this.props.cx || "5"}
            cy={this.props.cy || "5"}
            />
        </svg>
      </span>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressCircleEnd)