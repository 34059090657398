import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../actions/index'
import CSSTransition from 'react-transition-group/CSSTransition';
import { theme } from '../../config';
import Button from '../UI/Button';
import TextInput from '../UI/TextInput';

import icon from '../../images/q6-icon.png'
import icon2 from '../../images/q6-5-icon.png'
import QuizNavigation from './QuizNavigation';
import Footer from '../Footer';

class FourTwo extends Component<{}> {
  
  constructor(props){
    super(props)
  
    this.state = {
      email: '',
      invalidEmail: false
    }
  }
  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  submitEmail = async () => {
    if(this.validateEmail(this.state.email)){
      let info = await fetch('/api/q4',{
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({info: {email: this.state.email, completed: true}, user: this.props.redux.user_info})})
          .then(r=> r.json())
      
      this.props.answerFour(info.answer_4)
      let totalCats = this.props.redux.answer_1.number_of_cats
      window.gtag('event', 'Question 4', {
        'event_category': 'Quiz',
        'event_label': totalCats > 1 ? totalCats+' cats' : totalCats+' cat'
      })
      setTimeout(()=>this.setState({loading: false}), 1000)
    } else {
      clearTimeout(this.validationReset)
      this.setState({invalidEntry: false}, ()=>{
        this.setState({invalidEntry: true})
        this.validationReset = setTimeout(()=>{
          this.setState({invalidEntry: false})
        }, 2000)
      })
    }
  }
  onChange = ({prop, value}) => {
    this.setState({[prop]: value})
  }
  render() {
    return (
      <CSSTransition
        in={this.props.open}
        timeout={1000}
        classNames="quiz-slide"
        unmountOnExit
      >
        <div className={"question-container six"} style={{borderColor: theme.primary.regular}}> 
          <div className={"question-container-inner"}>
            <div>
              <div>
              My email is: &nbsp;
                <TextInput
                  color="#000"
                  name="email"
                  placeholder="your@email.com"
                  placeholderColor="#ccc"
                  value={this.state.email}
                  onChange={this.onChange}
                  />
                  { this.state.invalidEntry &&
                    <p className="ani-error" style={{color: theme.error.light, marginTop: -2, fontSize: 12, position: 'absolute'}}>Please enter a valid email address</p>
                  }
              </div>
              <Button style={{marginTop: 20}} onClick={this.submitEmail} className={'next-six'} text="NEXT" />
            </div>
            <p className="question-footer-text" style={{bottom: 10}}>
              <img src={icon} alt="" style={{maxWidth: 20, position: 'relative', bottom: -1}}/> Made in USA <img src={icon2} style={{maxWidth: 20, height: 'auto', position: 'relative', bottom: -5}} alt="PrettyPlease"/> Shipping is free</p>
          </div>
          <QuizNavigation page={4} />
          <Footer light style={{position: 'absolute', bottom: -100, fontSize: 12}}/>
        </div>

      </CSSTransition>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FourTwo)