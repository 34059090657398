import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../../actions/index'
import ProgressCircle from './ProgressCircle';
import ProgressLine from './ProgressLine';
import { theme } from '../../../config';

import './css/progress-bar.css'
import ProgressBarUnitBGLayer from './ProgressBarUnitBGLayer';

class ProgressBarUnit extends Component<{}> {
  
  constructor(props){
    super(props)
  
    this.state = {
      progress: .00001,
    }
  }

  completed = () => {
    this.setState({
      completed: true,
      fill: theme.progress.fill || theme.progress.primary,
      circumference: 0
    })
  }
  
  setCircumference = (obj) => {
    this.setState(obj)
  }
  render() {
    return (
      <div className="progress-bar-wrap" style={{maxWidth: window.innerWidth >= 768 ? 73 : 32}}>
        <ProgressBarUnitBGLayer 
          setCircumference={this.setCircumference}
          completed={this.props.completed}
          circumference={this.state.circumference}
          width={this.props.width}
          color={'#ddd'}
          {... this.state} />
        <div className="progression-wrap">
          <ProgressCircle 
            setCircumference={this.setCircumference}
            completed={this.props.completed}
            circumference={this.state.circumference}
            offset={ this.props.completed ? this.state.offset : this.state.circumference }
            fill={this.props.completed ? theme.primary.regular : 'transparent'} />
          <ProgressLine
            completed={this.props.completed}
            width={this.props.completed ? this.props.width : .0000001}/>
        </div>
      </div>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBarUnit)