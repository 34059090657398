import React, { Component } from 'react'

class Footer extends Component<{}> {
  render() {
    return (
      <div id="footer-links" className={this.props.light && "light"} style={{...this.props.style}}>
        <a className="no-utms" href="https://prettylittercats.com/pages/privacy-policy">Privacy</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a className="no-utms" href="https://prettylittercats.com/pages/terms-conditions">Terms</a>
      </div>
    )
  }
}

export default Footer