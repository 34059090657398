import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../actions/index'
import CSSTransition from 'react-transition-group/CSSTransition';
import { theme } from '../../config';
import Button from '../UI/Button';

import icon from '../../images/q5-icon.png'
import QuizNavigation from './QuizNavigation';
import Footer from '../Footer';

class Five extends Component<{}> {
  
  constructor(props){
    super(props)
  
    this.state = {}
  }
  
  selectEnvironment = (props) => {
    this.setState({
      [`cat_${props.catNumber}_env`]: props.text
    })
  }
  submitCatEnvironmentOnSelect = async (selection) => {
    let data = {cat_1_env: selection.text, completed: true}
    this.setState({loading: selection.text, cat_1_env: selection.text})
    let info = await fetch('/api/q5',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({info: data, user: this.props.redux.user_info})})
        .then(r=> r.json())
    
    
    this.props.answerFive(info.answer_5)
    let totalCats = this.props.redux.answer_1.number_of_cats
    window.gtag('event', 'Question 5', {
      'event_category': 'Quiz',
      'event_label': totalCats > 1 ? totalCats+' cats' : totalCats+' cat'
    })
    setTimeout(()=>this.setState({loading: false}), 1000)
  }
  submitCatEnvironment = async () => {
    let validated = true

    for (let i = 0; i < this.props.redux.answer_1.number_of_cats; i++) {
      let gender = this.state[`cat_${i+1}_env`]
      if(!gender){
        validated = false
      }
    }

    if(validated){
        
      let info = await fetch('/api/q5',{
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({info:{...this.state, completed: true}, user: this.props.redux.user_info})})
          .then(r=> r.json())
      
      
      this.props.answerFive(info.answer_5)
      let totalCats = this.props.redux.answer_1.number_of_cats
      window.gtag('event', 'Question 5', {
        'event_category': 'Quiz',
        'event_label': totalCats > 1 ? totalCats+' cats' : totalCats+' cat'
      })
      setTimeout(()=>this.setState({loading: false}), 1000)
    
    } else {
      clearTimeout(this.validationReset)

      this.setState({invalidEntry: false}, ()=>{
        this.setState({invalidEntry: true})
        this.validationReset = setTimeout(()=>{
          this.setState({invalidEntry: false})
        }, 2000)
      })
    }
  }
  async componentWillReceiveProps(nuProps){
    if(nuProps.redux.answer_1.number_of_cats){
      let cats = this.props.redux.answer_1.number_of_cats
      for (let i = 0; i < cats; i++) {
        if(!this.state[`cat_${i+1}_env`]){
          await this.setState({[`cat_${i+1}_env`]: false})
        }
      }
    }
  }
  render() {
    return (
      <CSSTransition
        in={this.props.open}
        timeout={1000}
        classNames="quiz-slide"
        unmountOnExit
      >
        
        <div className={"question-container five"} style={{borderColor: theme.primary.regular}}> 
        {
          this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats === 1 &&
            <CatEnvironment loading={this.state.loading} energy={this.state.cat_1_env} onSelect={this.submitCatEnvironmentOnSelect} selection={this.state.cat_1_env} catNumber={1} catNames={this.props.redux.answer_2}/>
        }
        {
          this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats > 1 &&
            <CatEnvironment onSelect={this.selectEnvironment} selection={this.state.cat_1_env} catNumber={1} catNames={this.props.redux.answer_2}/>
        }
        {
          this.props.redux.answer_2 && this.props.redux.answer_1.number_of_cats > 1 &&
            <CatEnvironment onSelect={this.selectEnvironment} selection={this.state.cat_2_env} catNumber={2} catNames={this.props.redux.answer_2}/>
        }
        {
          this.props.redux.answer_2 && this.props.redux.answer_1.number_of_cats > 2 &&
            <CatEnvironment onSelect={this.selectEnvironment} selection={this.state.cat_3_env} catNumber={3} catNames={this.props.redux.answer_2}/>
        }
        {
          this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats > 1 &&
            <Button
              text="NEXT"
              onClick={this.submitCatEnvironment}
              style={{marginBottom: 20, marginTop: 20, maxWidth: 210}}/>
        }
        { this.state.invalidEntry && this.props.open ?
          <p className={"ani-error"+' total'+this.props.redux.answer_1.number_of_cats } style={{color: theme.error.light, marginLeft: 0, fontSize: 12, position: 'absolute'}}>Please make sure you select an option</p>
          : null
        }
        <p className="question-footer-text"><img src={icon} alt="" style={{maxWidth: 20, height: 'auto', position: 'relative', bottom: -3}}/> Ultra-premium ingredients mean no food fillers! (Grain-free, no corn, no wheat, no soy)</p>
        <QuizNavigation page={5} />
        <Footer light style={{position: 'absolute', bottom: -100, fontSize: 12}}/>
      </div>

      </CSSTransition>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Five)

const CatEnvironment = (props) => (
  <div className={'question-container-inner'}>
    My cat { props.catNames[`cat_${props.catNumber}_name`] }:
    <div className="env-selector">
      <Button
        style={{maxWidth: 210}}
        onClick={props.onSelect}
        catNumber={props.catNumber}
        loading={props.loading === 'Loves the Outdoors!'}
        focused={props.selection === 'Loves the Outdoors!'}
        text="Loves the Outdoors!"
        focusBackgroundColor={theme.primary.regular}
        focusTextColor={theme.primary.contrast} />
      <Button
        style={{maxWidth: 210}}
        onClick={props.onSelect}
        loading={props.loading === 'Loves the Couch!'}
        focused={props.selection === 'Loves the Couch!'}
        catNumber={props.catNumber}
        text="Loves the Couch!"
        focusBackgroundColor={theme.primary.regular}
        focusTextColor={theme.primary.contrast} />
      <Button
        style={{maxWidth: 210}}
        onClick={props.onSelect}
        loading={props.loading === 'Loves Both!'}
        focused={props.selection === 'Loves Both!'}
        catNumber={props.catNumber}
        className={'last'}
        text="Loves Both!"
        focusBackgroundColor={theme.primary.regular}
        focusTextColor={theme.primary.contrast} />
    </div>
  </div>
)