import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../actions/index'
import CSSTransition from 'react-transition-group/CSSTransition';
import ReactPixel from 'react-facebook-pixel';
import { theme } from '../../config';
import Button from '../UI/Button';

import icon from '../../images/q8-icon.png'
import QuizNavigation from './QuizNavigation';
import Footer from '../Footer';

class Eight extends Component<{}> {
  
  constructor(props){
    super(props)
  
    this.state = {
      shop_habit: null
    }
  }
  
  onSelect = async (props) => {
    this.sending = true
    this.setState({
      shop_habit: props.text,
      loading: props.text,
    })

    let info = await fetch('/api/q8',{
      method: 'POST', shop_habbit: props.text,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({info: {shop_habit: props.text, completed: true}, user: this.props.redux.user_info})})
        .then(r=> r.json())
    
    info.answer_8.completed = true

    let totalCats = this.props.redux.answer_1.number_of_cats

    window.gtag('event', 'Question 8', {
      'event_category': 'Quiz',
      'event_label': totalCats > 1 ? totalCats+' cats' : totalCats+' cat'
    })
    ReactPixel.trackCustom( 'quizcomplete' ) 
    this.props.answerEight(info.answer_8)
    setTimeout(()=>this.setState({loading: false}), 1000)
    this.sending = false
  }
  render() {
    return (
      <CSSTransition
        in={this.props.open}
        timeout={1000}
        classNames="quiz-slide"
        unmountOnExit
      >
        
      <div className={"question-container eight"} style={{borderColor: theme.primary.regular}}> 
        <div className={"question-container-inner"}>
          How do you typically shop for cat food?
          <div className="env-selector">
            <Button
              style={{maxWidth: 210}}
              onClick={this.onSelect}
              text="Grocery Store"
              focused={this.state.shop_habit === 'Grocery Store'}
              loading={this.state.loading === 'Grocery Store'} 
              focusBackgroundColor={theme.primary.regular}
              focusTextColor={theme.primary.contrast} />
            <Button
              style={{maxWidth: 210}}
              onClick={this.onSelect}
              text="Pet Store"
              focused={this.state.shop_habit === 'Pet Store'}
              loading={this.state.loading === 'Pet Store'} 
              focusBackgroundColor={theme.primary.regular}
              focusTextColor={theme.primary.contrast} />
          </div>
          <div className="env-selector" style={{marginTop: 0}}>
            <Button
              style={{maxWidth: 210}}
              onClick={this.onSelect}
              text="Online"
              focused={this.state.shop_habit === 'Online'}
              focusBackgroundColor={theme.primary.regular}
              loading={this.state.loading === 'Online'} 
              focusTextColor={theme.primary.contrast} />
              
            <Button
              style={{maxWidth: 210}}
              onClick={this.onSelect}
              text="Vet or Pharmacy"
              focused={this.state.shop_habit === 'Vet or Pharmacy'}
              focusBackgroundColor={theme.primary.regular}
              loading={this.state.loading === 'Vet or Pharmacy'} 
              focusTextColor={theme.primary.contrast} />
          </div>
        </div>
        {/* <Button
          text="NEXT"
          onClick={this.submitCatDigestion}
          style={{marginBottom: 20, marginTop: 20, maxWidth: 210}}/> */}
        <p className="question-footer-text"><img src={icon} alt="" style={{maxWidth: 20, height: 'auto', position: 'relative', bottom: -3}}/> Created by pet nutrition experts, PrettyPlease was tested for over two years!</p>
        <QuizNavigation page={8} />
        <Footer light style={{position: 'absolute', bottom: -100, fontSize: 12}}/>
      </div>

      </CSSTransition>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Eight)
