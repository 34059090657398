import React, { Component } from 'react';

import Home from './container/Home';
import Quiz from './container/Quiz';
import QuizTwo from './container/QuizTwo';

import './lib/extensions'

//REDUX
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import reducers from './reducers'
import './components/UI/css/ui.css'
import ReactPixel from 'react-facebook-pixel';
 
 
const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};

ReactPixel.init('753460134775804', advancedMatching, options);

const store = createStore(reducers, {})

class App extends Component {
  componentDidMount(){
    // if(window.Raven){
    //   window.Raven.config('https://33aa7e01106d451f8142b70b162cbf79@sentry.io/1416720').install()
    // } else {
    //   let connectDebugging = setInterval(()=>{
    //     if(window.Raven){
    //       window.Raven.config('https://33aa7e01106d451f8142b70b162cbf79@sentry.io/1416720').install()
    //       clearInterval(connectDebugging)
    //     }
    //   }, 1000)
    // }
  }
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Route exact path="/" component={Home} />
          <Route exact path="/quiz" component={QuizTwo} />
          {/* <Route exact path="/quiz-2" component={QuizTwo} /> */}
        </Router>
      </Provider>
    );
  }
}

export default App;
