import React, { Component } from 'react'
import { NavLink as Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../actions/index'
import ReactPixel from 'react-facebook-pixel';

// Styles
import './css/main.css'
import '../components/quiz/css/questions.css'

//Components
import ProgressBarContainer from '../components/quiz/progress-bar/ProgressBarContainer';
import One from '../components/quiz/One';
import Two from '../components/quiz/Two';
import Three from '../components/quiz/Three';
import Four from '../components/quiz/Four';
import Five from '../components/quiz/Five';
import Six from '../components/quiz/Six';
import Seven from '../components/quiz/Seven';
import Eight from '../components/quiz/Eight';
import Completed from '../components/quiz/Completed';
import Footer from '../components/Footer';
import ErrorBoundary from '../components/ErrorBoundary';

// Images
import logo from '../images/logo.png'

class Main extends Component<{}> {
  
  constructor(props){
    super(props)
  
    this.state = {}
  }

  getPreviousData = async () => {
    let user_info = await fetch('/api/load-client', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(r=>r.json()).catch(e=>console.log('no previous client'))
    if(user_info){
      if(user_info.answer_1) await this.props.answerOne(user_info.answer_1)
      if(user_info.answer_2) await this.props.answerTwo(user_info.answer_2)
      if(user_info.answer_3) await this.props.answerThree(user_info.answer_3)
      if(user_info.answer_4) await this.props.answerFour(user_info.answer_4)
      if(user_info.answer_5) await this.props.answerFive(user_info.answer_5)
      if(user_info.answer_6) await this.props.answerSix(user_info.answer_6)
      if(user_info.answer_7) await this.props.answerSeven(user_info.answer_7)
      if(user_info.answer_8) await this.props.answerEight(user_info.answer_8)
      if(user_info.answer_9) await this.props.answerNine(user_info.answer_9)
      this.setState({loading_complete: true})
      let user = {
        client_id: user_info._id,
        ip: user_info.ip,
        email: user_info.email,
        returning: user_info.returning,
        first_visit: user_info.first_visit,
        completed_quiz: user_info.completed_quiz,
      }
      this.props.updateUser(user)
    } else {
      this.setState({loading_complete: true})
    }
  }

  componentDidMount(){
    ReactPixel.pageView();
    ReactPixel.trackCustom( 'PPGotStarted' ) 
    this.setState({loading_complete: true}) //this.getPreviousData()
    setTimeout(()=>{
      if(navigator.appVersion.indexOf('MSIE 9.0') > -1){
        window.flexibility(document.documentElement);
      }
    }, 500)
  }
  componentWillReceiveProps(){
    setTimeout(()=>{
      if(navigator.appVersion.indexOf('MSIE 9.0') > -1){
        window.flexibility(document.documentElement);
      }
    }, 500)
  }
  render() {
    return (
        <div id="page" style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', minHeight: 600}}>
          { this.state.loading_complete &&
            <div className="quiz-wrap" id="quiz-container">
              <Link to="/">
                <img className="logo-heading" src={logo} alt="PrettyPlease Logo" />
              </Link>
              <ProgressBarContainer />
                
                <One open={!this.props.redux.answer_1.completed}/>

                <Two open={
                  this.props.redux.answer_1.completed && !this.props.redux.answer_2.completed
                }/>
                <Three open={
                  this.props.redux.answer_2.completed && !this.props.redux.answer_3.completed
                }/>
                <Four open={
                  this.props.redux.answer_3.completed && !this.props.redux.answer_4.completed
                }/>
                <Five open={
                  this.props.redux.answer_4.completed && !this.props.redux.answer_5.completed
                }/>
                <Six open={
                  this.props.redux.answer_5.completed && !this.props.redux.answer_6.completed
                }/>
                <Seven open={
                  this.props.redux.answer_6.completed && !this.props.redux.answer_7.completed
                }/>
                <Eight open={
                  this.props.redux.answer_7.completed && !this.props.redux.answer_8.completed
                }/>
                <Completed open={
                  this.props.redux.answer_1.completed &&
                  this.props.redux.answer_2.completed &&
                  this.props.redux.answer_3.completed &&
                  this.props.redux.answer_4.completed &&
                  this.props.redux.answer_5.completed &&
                  this.props.redux.answer_6.completed &&
                  this.props.redux.answer_7.completed &&
                  this.props.redux.answer_8.completed
                }/>
            </div>
          }
        </div>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)