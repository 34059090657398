import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../../actions/index'

import { theme } from '../../../config'


class ProgressCircle extends Component<{}> {
  constructor(props){
    super(props)

    this.state = {
      
    }
  
    this.circle = React.createRef();
    this.svg = React.createRef();
  }
  componentDidMount(){
    const circle = this.circle.current;
    const radius = circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;
    
    // Offset is used to determin what a full circle is, and then use the to reduce the dasharray offset
    const offset = circumference - (this.props.percent || 100) / 100 * circumference;
    
    // Setting the dash array/offset gives us a gap to work with when animating
    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = circumference;
    
    this.props.setCircumference({
      circumference,
      offset
    })
    
    this.setState({
      circumference,
      offset
    })
  }
  render() {
    return (
      <svg
        ref={this.svg}
        className="progress-ring"
        height={this.props.height || "10"}
        width={this.props.width || "10"}
        id={this.props.id}
        preserveAspectRatio="none"
      >
        <circle
          ref={this.circle}
          className={this.props.completed ? "progress-ring__t-0 progress-ring__circle" : "delay1 progress-ring__t-0 progress-ring__circle no-transition"}
          strokeWidth={"3"}
          style={{strokeDashoffset: this.props.offset}}
          stroke={theme.progress.regular}
          fill={this.props.fill || theme.progress.regular}
          r={this.props.r || '3'}
          cx={this.props.cx || "5"}
          cy={this.props.cy || "5"}
        />
      </svg>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressCircle)