import React, { Component } from 'react'
import SpinningWheel from './SpinningWheel'

import { theme } from '../../config';

class Button extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {}
  }
  render() {
    return (
        <button
          onClick={()=>this.props.onClick && this.props.onClick({...this.props})}
          className={this.props.className?"main-btn "+this.props.className : "main-btn"}
          id={this.props.id}
          style={{
            ...this.props.style,
            color: this.props.focused ? (this.props.focusTextColor || theme.primary.regular) : theme.primary.regular,
            borderColor: theme.primary.regular,
            backgroundColor: this.props.focused ? (this.props.focusBackgroundColor || theme.primary.regular) : this.props.loading && theme.primary.regular,
        }}>
          { this.props.loading ? <SpinningWheel style={{maxWidth: 20, marginBottom: -3}}/> : this.props.text}
        </button>
    )
  }
}

export default Button