import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../actions/index'
import CSSTransition from 'react-transition-group/CSSTransition';
import TextInput from '../UI/TextInput'
import { theme } from '../../config';
import icon from '../../images/q2-icon.png'
import Button from '../UI/Button';
import QuizNavigation from './QuizNavigation';
import Footer from '../Footer';

const initialState = {
  [`cat_1`]: false,
  [`cat_1_name`]: false,
  [`cat_1_age`]: false,
  [`cat_2`]: false,
  [`cat_2_name`]: false,
  [`cat_2_age`]: false,
  [`cat_3`]: false,
  [`cat_3_name`]: false,
  [`cat_3_age`]: false,
};

class Two extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = initialState // 
    
  }
  reset = () => {
    this.setState(initialState);
  }
  changeTextInput = ({prop, value}) => {
    this.setState({[prop]: value})
  }
  async componentWillReceiveProps(nuProps){
    //let oldProps = this.props
    
    //if(!objIsIdentical(oldProps.redux.answer_1, nuProps.redux.answer_1)){
      await this.reset()
      await this.setState({number_of_cats: this.props.redux.answer_1.number_of_cats})
      for (let i = 0; i < nuProps.redux.answer_1.number_of_cats; i++) {
        await this.setState({
          [`cat_${i+1}`]: this.props.redux.answer_2[`cat_${i+1}`] ? this.props.redux.answer_2[`cat_${i+1}`] : true,
          [`cat_${i+1}_name`]: this.props.redux.answer_2[`cat_${i+1}_name`] ? this.props.redux.answer_2[`cat_${i+1}_name`] : '',
          [`cat_${i+1}_age`]: this.props.redux.answer_2[`cat_${i+1}_age`] ? this.props.redux.answer_2[`cat_${i+1}_age`] : '',
        }) 
      }
    //} 
  }
  submitInformation = async () => {
    let validated = true
    for (let i = 0; i < this.props.redux.answer_1.number_of_cats; i++) {
      let name = this.state[`cat_${i+1}_name`]
      let age = this.state[`cat_${i+1}_age`]
      if(name === '' || age === ''){
        validated = false
      }
    }
    if(validated){
      let info = await fetch('/api/q2',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({info: {...this.state, completed: true}, user: this.props.redux.user_info})})
          .then(r=> r.json())

      this.props.answerTwo(info.answer_2)
      let totalCats = this.props.redux.answer_1.number_of_cats
      window.gtag('event', 'Question 2', {
        'event_category': 'Quiz',
        'event_label': totalCats > 1 ? totalCats+' cats' : totalCats+' cat'
      })
    } else {
      clearTimeout(this.validationReset)

      this.setState({invalidEntry: false}, ()=>{
        this.setState({invalidEntry: true})
        this.validationReset = setTimeout(()=>{
          this.setState({invalidEntry: false})
        }, 2000)
      })
    }

  }
  render() {
    return (
      <CSSTransition
        in={this.props.open}
        classNames="quiz-slide"
        unmountOnExit
        onEnter={() => {
          console.log({props: this.props})
        }}
        timeout={1000}
      >
        <div className={"question-container two"} style={{borderColor: theme.primary.regular}}> 
          
          { this.state.cat_1 &&
              <CatsDetails
                changeTextInput={this.changeTextInput}
                number={1}
                name={this.state.cat_1_name}
                age={this.state.cat_1_age}
                multipleCats={this.state}
              />
          }

          { this.state.cat_2 &&
              <CatsDetails
                changeTextInput={this.changeTextInput}
                number={2}
                name={this.state.cat_2_name}
                age={this.state.cat_2_age}
                multipleCats={this.state}
              />
          }

          { this.state.cat_3 &&
              <CatsDetails
                changeTextInput={this.changeTextInput}
                number={3}
                name={this.state.cat_3_name}
                age={this.state.cat_3_age}
                multipleCats={this.state}
              />
          }
          <Button text="NEXT" onClick={this.submitInformation} style={{marginBottom: 40, marginTop: 20}}/>
          { this.state.invalidEntry && this.props.open &&
            <p className={"ani-error"+' total'+this.props.redux.answer_1.number_of_cats }style={{color: theme.error.light, marginLeft: 0, fontSize: 12, position: 'absolute'}}>Please fill in all fields</p>
          }
          <p className="question-footer-text"><img src={icon} alt="" style={{maxWidth: 20, height: 'auto', position: 'relative', bottom: -5}}/> PrettyPlease contains 24 essential vitamins and nutrients </p>
          <QuizNavigation page={2} />
          <Footer light style={{position: 'absolute', bottom: -100, fontSize: 12}}/>
        </div>
      </CSSTransition>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Two)


const CatsDetails = (props) => (
  <div className={"question-container-inner"}>
    <p>
      {props.number === 1 && !props.multipleCats.cat_2 && <span>My cat's name is: </span> }
      {props.number === 1 && props.multipleCats.cat_2 && <span>My 1st cat's name is: </span> }
      {props.number === 2 && 'My 2nd cat\'s name is: '}
      {props.number === 3 && 'My 3rd cat\'s name is: '}<TextInput
        color="#000"
        name={`cat_${props.number}_name`}
        onChange={props.changeTextInput}
        placeholder="cats name"
        placeholderColor="#ccc"
        value={props.name}/>
    </p>
    <p className="bottom-text">and is &nbsp;
      <TextInput
        accepts="int"
        width="50px"
        color="#000"
        name={`cat_${props.number}_age`}
        onChange={props.changeTextInput}
        value={props.age}
        placeholder="age"
        placeholderColor="#ccc"
      />
    years old</p>
  </div>
)