import createReducer from '../lib/createReducer'
import * as types from '../actions/types'

export const answer_1 = createReducer({completed: false},{
	[types.Q1](state, action){
		let newState = action.payload
		return newState
	}
})

export const answer_2 = createReducer({completed: false},{
	[types.Q2](state, action){
		let newState = action.payload
		return newState
	}
})

export const answer_3 = createReducer({completed: false},{
	[types.Q3](state, action){
		let newState = action.payload
		return newState
	}
})

export const answer_4 = createReducer({completed: false},{
	[types.Q4](state, action){
		let newState = action.payload
		return newState
	}
})

// export const answer_5 = createReducer({completed: false},{
// 	[types.Q5](state, action){
// 		let newState = action.payload
// 		return newState
// 	}
// })

// export const answer_6 = createReducer({completed: false},{
// 	[types.Q6](state, action){
// 		let newState = action.payload
// 		return newState
// 	}
// })

// export const answer_7 = createReducer({completed: false},{
// 	[types.Q7](state, action){
// 		let newState = action.payload
// 		return newState
// 	}
// })

// export const answer_8 = createReducer({completed: false},{
// 	[types.Q8](state, action){
// 		let newState = action.payload
// 		return newState
// 	}
// })

// export const answer_9 = createReducer({completed: false},{
// 	[types.Q9](state, action){
// 		let newState = action.payload
// 		return newState
// 	}
// })