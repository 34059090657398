import React, { Component } from 'react'

class SpinningWheel extends Component<{}> {
  render() {
    return (
        <div>
          <img
            className='spinning'
            style={{...this.props.style, height: 'auto'}}
            src={require('../../images/final-complete.png')}
            alt="loading"
          />
        </div>
    )
  }
}

export default SpinningWheel