import React, { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    // window.location.href = 'http://www.prettypleasecats.com'
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return  <div>
                <h1>Your browser doesnt support this app :(</h1>
                <h2>Thats okay!  We'll load another page for you :)</h2>
              </div>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary