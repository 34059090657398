import React, { Component } from 'react'

class SpinningWheel extends Component<{}> {
  constructor(props){
    super(props)
    if(navigator.appVersion.indexOf('MSIE 10') === -1 && navigator.appVersion.indexOf('MSIE 9') === -1){
      this.state = {
        scale: this.props & this.props.delay ? 'scale(0,0)' : null
      }
    } else {
      this.state = {
        scale: 'scale(0,0)'
      }
    }
  }
  componentDidMount(){
    if(navigator.appVersion.indexOf('MSIE 10') === -1 && navigator.appVersion.indexOf('MSIE 9') === -1){
      const {delay} = this.props
      if(delay){
        setTimeout(()=>{
          this.setState({
            delayFinished: true,
            scale: 'scale(1,1)'
          })
        }, delay || 2000)
      }
    }
  }
  render() {
    const {delay} = this.props
    const {delayFinished} = this.state
    return (
        <div>
          {
            delay && delayFinished && <img
              className='wobble'
              style={{...this.props.style, transform: this.state.scale}}
              src={require('../../images/final-complete.png')}
              alt="loading"
            />
          }
          {
            !delay && <img
              className='wobble'
              style={{...this.props.style}}
              src={require('../../images/final-complete.png')}
              alt="loading"
            />
          }
        </div>
    )
  }
}

export default SpinningWheel