import React, { Component } from 'react'
import { theme } from '../../../config';
import { sizes } from '../../../config/sizes';

class ProgressLine extends Component<{}> {
  constructor(props){
    super(props)
  
    this.state = {
      lineWidth: window.innerWidth < 768 ? sizes.progress.mobile : sizes.progress.desktop
    }
  }
  componentDidMount(){
    if(window.innerWidth < 768){
      this.setState({lineWidth: sizes.progress.mobile})
    } else {
      this.setState({lineWidth: sizes.progress.desktop})
    }
    window.addEventListener("resize", ()=>{
      if(window.innerWidth < 768){
        this.setState({lineWidth: sizes.progress.mobile})
      } else {
        this.setState({lineWidth: sizes.progress.desktop})
      }
    })
  }
  render() {
    return (
      <svg
        height="10"
        width={this.state.lineWidth}
        style={{marginRight: -2, marginLeft: -2}}
      >
          <path
            className={this.props.completed ? "delay1 progress-ring__t-0 progress-ring__line" : "progress-ring__t-1 progress-ring__line"}
            stroke={theme.progress.line || theme.progress.primary}
            strokeWidth={this.props.strokeWidth || 2}
            d={this.props.d || `M0 5 l${this.props.width || 600} 0`}
          />
      </svg>
    )
  }
}

export default ProgressLine