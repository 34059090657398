export const theme = {
  primary: {
    light: '',
    regular: '#61cbd6',
    dark: '',
    contrast: '#fff'
  },
  secondary: {
    light: '',
    regular: '',
    dark: '',
    contrast: ''
  },
  progress: {
    line: '#61cbd6',
    circle: '#61cbd6',
    fill: '#61cbd6',
    light: '',
    regular: '#61cbd6',
    dark: '',
  },
  error: {
    light: '#f77'
  }
}