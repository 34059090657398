import React, { Component } from 'react'

class Dropdown extends Component<{}> {
  handleDropdownSelect = (e) => {
    // this.props.onSelect({prop: this.props.name, value: e.target.value})
  }
  componentDidMount() {
    // let defaultValue = this.props.options.find(e => e.selected).value
    // this.props.onSelect({prop: this.props.name, value: false})
  }
  render() {
    return (
      <div className="quiz_dropdown" style={this.props.style}>
          <div className="select">
            <select name="quiz_dropdown" id="quiz_dropdown" defaultValue={this.props.defaultValue} onChange={this.handleDropdownSelect}>
            { this.props.options.map(option => (
              <option disabled={option.disabled} key={option.value} value={option.value}>{option.value}</option>
            ))}
              </select>
          </div>
      </div>
    )
  }
}

export default Dropdown