import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../../actions/index'
import CSSTransition from 'react-transition-group/CSSTransition';
import { theme } from '../../config';
import Button from '../UI/Button';

import icon from '../../images/q4-icon.png'
import QuizNavigation from './QuizNavigation';
import Footer from '../Footer';
import Dropdown from '../UI/Dropdown';

const initialState = {
  invalidEntry: false,
  loading: false,
  load: false
}
class Four extends Component<{}> {
  
  constructor(props){
    super(props)
  
    this.state = Object.assign({}, initialState)
  }
  async reset() {
    delete this.state.cat_2_weight;
    delete this.state.cat_2_birth_month;
    delete this.state.cat_2_birth_day;
    delete this.state.cat_3_weight;
    delete this.state.cat_3_birth_month;
    delete this.state.cat_3_birth_day;
    await this.setState(this.state);
  }
  async componentWillReceiveProps(){
    await this.reset()
    setTimeout(()=>this.setState({load: true}), 3000)
  }
  componentDidMount(){
    this.setState({load: true})
  }
  selectOption = ({prop, value}) => {
    this.setState({
      [prop]: value
    })
  }
  submitBreeds = async () => {
    let validationFields = Object.assign({}, this.state)
    
    delete validationFields.invalidEntry
    delete validationFields.loading
    delete validationFields.load

    validationFields = Object.getOwnPropertyNames(validationFields)
    let body = {}
    let validated = true

    for (let i = 0; i < validationFields.length; i++) {
      
      if(!this.state[validationFields[i]] || this.state[validationFields[i]] === ''){
        validated = false
      } else {
        body[validationFields[i]] = this.state[validationFields[i]]
      }
    }
    // if(validated){  
      let info = await fetch('/api/q4',{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({info: {...body, completed: true}, user: this.props.redux.user_info})})
          .then(r=> r.json())

      this.props.answerFour(info.answer_4)
      let totalCats = this.props.redux.answer_1.number_of_cats
      window.gtag('event', 'Question 4', {
        'event_category': 'Quiz',
        'event_label': totalCats > 1 ? totalCats+' cats' : totalCats+' cat'
      })
      setTimeout(()=>this.setState({loading: false}), 1000)
    // } else {
    //   clearTimeout(this.validationReset)

    //   this.setState({invalidEntry: false}, ()=>{
    //     this.setState({invalidEntry: true})
    //     this.validationReset = setTimeout(()=>{
    //       this.setState({invalidEntry: false})
    //     }, 2000)
    //   })
    // }
  }
  render() {
    return (
      <CSSTransition
        in={this.props.open}
        timeout={1000}
        classNames="quiz-slide"
        unmountOnExit
      >
        
        <div className={"question-container four"} style={{borderColor: theme.primary.regular}}> 
          {
            this.state.load && this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats === 1 &&
              <CatWeight energy={this.state.cat_1_energy} singleButton={true} loading={this.state.loading} onSelect={this.selectOption} selection={this.state.cat_1_energy} catNumber={1} catNames={this.props.redux.answer_2}/>
          }

          {
            this.state.load && this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats > 1 &&
              <CatWeight onSelect={this.selectOption} selection={this.state.cat_1_energy} catNumber={1} catNames={this.props.redux.answer_2}/>
          }
          
          {
            this.state.load && this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats > 1 &&
              <hr />
          }

          {
            this.state.load && this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats > 1 &&
              <CatWeight onSelect={this.selectOption} selection={this.state.cat_2_energy} catNumber={2} catNames={this.props.redux.answer_2}/>
          }



          {
            this.state.load && this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats > 2 &&
            <hr />
          }


          {
            this.state.load && this.props.redux.answer_1 && this.props.redux.answer_1.number_of_cats > 2 &&
              <CatWeight onSelect={this.selectOption} selection={this.state.cat_3_energy} catNumber={3} catNames={this.props.redux.answer_2}/>
          }

        <Button text="NEXT" onClick={this.submitBreeds} style={{marginBottom: 40, marginTop: 20}}/>

          { this.state.invalidEntry && this.props.open &&
            <p className={"ani-error"+' total'+this.props.redux.answer_1.number_of_cats } style={{color: theme.error.light, marginLeft: 0, fontSize: 12, position: 'absolute'}}>Please make sure you select an option</p>
          }

        <p className="question-footer-text"><img src={icon} alt="" style={{maxWidth: 20, height: 'auto', position: 'relative', bottom: -5}}/> "My cats are crazy for this food." - Linda J.</p>

        <QuizNavigation page={4} />

        <Footer light style={{position: 'absolute', bottom: -100, fontSize: 12}}/>

      </div>

      </CSSTransition>
    )
  }
}

function mapStateToProps(redux) {
  return {
    redux
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Four)

const CatWeight = (props) => (
  <div className={"question-container-inner"}>
    My cat { props.catNames[`cat_${props.catNumber}_name`] } weighs:
    <div className="weight-selector">
      <Dropdown
        onSelect={props.onSelect}
        catNumber={props.catNumber}
        style={{marginTop: 10, marginBottom: 10}}
        name={'cat_'+props.catNumber+'_weight'}
        defaultValue={'Pick Weight'}
        options={[
          {value: 'Pick Weight', disabled: true},
          {value: '1 - 4 lbs'},
          {value: '5 - 8 lbs'},
          {value: '9 - 12 lbs'},
          {value: '12+ lbs'}
        ]} />
    </div>
    and is born on
    <div className="bday-selector">
      <Dropdown
        onSelect={props.onSelect}
        catNumber={props.catNumber}
        style={{marginTop: 10, marginBottom: 10, minWidth: 120}}
        defaultValue={'Month'}
        options={[
          {value: 'Month', disabled: true},
          {value: 'Not Sure'},
          {value: 'January'},
          {value: 'February'},
          {value: 'March'},
          {value: 'April'},
          {value: 'May'},
          {value: 'June'},
          {value: 'July'},
          {value: 'August'},
          {value: 'September'},
          {value: 'October'},
          {value: 'November'},
          {value: 'December'}
        ]}
        name={'cat_'+props.catNumber+'_birth_month'}
      />
      <Dropdown
        onSelect={props.onSelect}
        catNumber={props.catNumber}
        style={{float: 'left',marginTop: 10, marginBottom: 10, minWidth: 120}}
        defaultValue={'Day'}
        options={[
          {value: 'Day', disabled: true},
          {value: 'Not Sure'},
          {value: '01'},
          {value: '02'},
          {value: '03'},
          {value: '04'},
          {value: '05'},
          {value: '06'},
          {value: '07'},
          {value: '08'},
          {value: '09'},
          {value: '10'},
          {value: '11'},
          {value: '12'},
          {value: '13'},
          {value: '14'},
          {value: '15'},
          {value: '16'},
          {value: '17'},
          {value: '18'},
          {value: '19'},
          {value: '20'},
          {value: '21'},
          {value: '22'},
          {value: '23'},
          {value: '24'},
          {value: '25'},
          {value: '26'},
          {value: '27'},
          {value: '28'},
          {value: '29'},
          {value: '30'},
          {value: '31'}
        ]} 
        name={'cat_'+props.catNumber+'_birth_day'}
      />
    </div>
  </div>
)